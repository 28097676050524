import { ReactNode, createContext, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

type FlagToggleContextType = {
  isFlagToggle: boolean;
  setFlagToggle: Dispatch<SetStateAction<boolean>>;
};

interface MyProviderProps {
  children: ReactNode;
}

export const FlagToggleContext = createContext<
  FlagToggleContextType | undefined
>(undefined);

export default function FlagToggle({ children }: MyProviderProps) {
  const [isFlagToggle, setFlagToggle] = useState<boolean>(false);
  return (
    <FlagToggleContext.Provider value={{ isFlagToggle, setFlagToggle }}>
      {children}
    </FlagToggleContext.Provider>
  );
}
